.stars {
  --star-size: 20px;
  --star-color: #ced3e4;
  --star-background: #f78f1e;
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  line-height: 1;

  &::before {
    content: '★ ★ ★ ★ ★';
    letter-spacing: 0;
    background: linear-gradient(
      90deg,
      var(--star-background) var(--percent),
      var(--star-color) var(--percent)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.review {
  &__customer-information {
    > span {
      overflow: hidden;
      white-space: initial;
    }
  }

  &__customer-review {
    span {
      overflow: hidden;
      white-space: initial;
    }
  }
}
